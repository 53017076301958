import React, { useEffect, useState } from 'react'
import arrowDown from 'images/ui/arrow_down.png'
import { useNavigate } from '@reach/router'
import PortfolioContextProvider from 'context/PortfolioContext'
import { handleNextPage } from 'Helpers/functions'
import 'scss/nextbtn.scss'

const NextBtn = ({ domClassesToHide, nextBtnTitle, nextBtnTitleSize, nextPageLink, translateAmount, rotateAmount }) => {
  // const {hidePageWithTransition} = useContext(PortfolioContext);

  const [fontSize, setFontSize] = useState(nextBtnTitleSize)
  const [transAmount, setTransAmount] = useState(translateAmount);
  
  const navigate = useNavigate()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      // ajust font size depending screen size
      if (window.innerWidth < 768) {
        setFontSize(nextBtnTitleSize - .3)
        setTransAmount(Math.floor(translateAmount) + 1.2)
        //console.log("passed nextTitleFontSize translateAmount - amount sm device : ", fontSize + ", " + transAmount);
      }
      else {
        setFontSize(nextBtnTitleSize)
        setTransAmount(parseFloat(translateAmount))
      }
      //console.log("passed nextTitleFontSize translateAmount : ", fontSize + ", " + transAmount);
    }
  }, [fontSize, transAmount]);

  return (
    <PortfolioContextProvider>
      <div className="next__btn slide-in-content">
        <div className="next__btnHexBorder" onClick={() => handleNextPage(nextPageLink, domClassesToHide)}>
          <div className="next__btnHexMain">
            <span className="next__btnTitle" style={{ fontSize: `${fontSize}rem`}}>
              {nextBtnTitle}
            </span>
            <span className="next__btnArrow" style={{transform: `translateY(${transAmount}rem) rotate(${rotateAmount}deg)`}}>
              <img width="auto" height="auto" className="next__btnArrowDown" src={arrowDown} alt="▼" />
            </span>
          </div>
        </div>
      </div>

    </PortfolioContextProvider>
  )
}

export default NextBtn
