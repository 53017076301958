import React from 'react'
// import { Link } from "gatsby"
// import Image from ".components/image"
import SEO from 'components/seo'
import Layout from 'components/layout'
import Landing from 'components/landing/landing'
import NextBtn from 'ui/next-btn'
import "scss/style.scss"

const IndexPage = () => {
  
  return (
    <Layout pageTitle="INDEX">
      <SEO
        title="Home"
        image="/img/index-preview.png"
        description="Hello World! My name is Aymane Lassfar. I'm a passionate Web Developer."
      />
      <Landing id="landing" />
      <NextBtn
        domClassesToHide=".header,.landing__content,.next__btn,.landing__particles"
        nextBtnTitle="ABOUT"
        nextPageLink="/about" />
    </Layout>
  );
}

export default IndexPage
