import React, { useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { showPageWithTransition, createParticles } from 'Helpers/functions'
import 'scss/landing.scss'
// import 'scss/atom.scss'

const Landing = () => {

  useEffect(() => {
    // slide show page
    showPageWithTransition();

    const particInterv = 
    setInterval(() => {
      // MAKE PARTICLE EFFECT
      createParticles();
      // DETECT IF ROUTE CHANGES
      return globalHistory.listen(({action}) => {
        action === "PUSH" && clearInterval(particInterv); //+THEN REMOVE PARTICLE EFFECT
        //console.log("particInterv was cleared");
      });
    }, 200);
  }, []);

  return (
    <div className="landing">
      <div className="landing__content">
        <div className="landing__developerInfo" data-speed="1">
          <h1 className="landing__firstTitle">Hello World!</h1>
          <span className="landing__normalText">My name is&nbsp;</span>
          <span className="landing__developer">Aymane Lassfar</span>
        </div>
        <div className="landing__description" data-speed="1">
          <span className="landing__normalText">I’m a passionate&nbsp;</span>
          <span className="landing__profession">Web Developer</span>
        </div>
      </div>

      <div className="landing__particles" id="landingParticles">
        {/*  */}
      </div>
      {/* <div className="square__main"> */}
        {/* SQUARE SHAPE */}
      {/* </div> */}
      {/* <div className="atom__main">
        <div className="atomOne"></div>
        <div className="atomTwo"></div>
        <div className="atomThree"></div>
        <div className="atomFour"></div>
      </div> */}
      {/* <Hexagon mainColor="#00000000" hexHeight="2.8rem" hexWidth="4.8rem" zIndex="3" top="15%" left="80%" rotate="90deg" /> */}
    </div>
  )
}

export default Landing
